var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"adminApp"}},[_c('v-navigation-drawer',{staticClass:"bg-secondary",attrs:{"width":"280","right":_vm.lang == 'ar',"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('h2',{staticClass:"my-4 clr-primary text-center"},[_vm._v(_vm._s(_vm.$t("V-shape")))]),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[(
          _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_home')
        )?_c('v-list-item',{attrs:{"link":"","to":"/admin/home"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Home")))])],1):_vm._e(),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_division')) ||
          _vm.user.permissions.includes('get_group') ||
          _vm.user.permissions.includes('get_nutrition_fact') ||
          _vm.user.permissions.includes('get_cuisine') ||
          _vm.user.permissions.includes('get_tool') ||
          _vm.user.permissions.includes('get_unit') ||
          _vm.user.permissions.includes('get_country') ||
          _vm.user.permissions.includes('get_city') ||
          _vm.user.permissions.includes('get_period') ||
          _vm.user.permissions.includes('get_bank_name') ||
          _vm.user.permissions.includes('get_company') ||
          _vm.user.permissions.includes('get_group_name') ||
          _vm.user.permissions.includes('get_package')
        )?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("SystemConstant")))])],1)]},proxy:true}],null,false,2093616583)},[(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_division')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/division"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Divisions")))])],1)],1):_vm._e(),(
            _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_group')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/group"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Groups")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_nutrition_fact')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/nutrition_fact"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("NutritionFacts")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_cuisine')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/cuisine"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Cuisines")))])],1)],1):_vm._e(),(
            _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_tool')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/tool"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Tools")))])],1)],1):_vm._e(),(
            _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_unit')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/unit"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Units")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_country')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/country"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Countries")))])],1)],1):_vm._e(),(
            _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_city')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/city"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Cities")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_branch')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/branch"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Branches")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_period')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/period"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Periods")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_bank_name')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/bank_name"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("BankNames")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_company')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/company"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Companies")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_group_name')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/group_name"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("GroupNames")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_package')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/package"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Packages")))])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","to":"/admin/packaging_group"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("PackagingGroups")))])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/admin/packaging_group_city_period"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("PackagingGroupCityPeriods")))])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/admin/complaint_division"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("ComplaintDivisions")))])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/admin/complaint_type"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("ComplaintTypes")))])],1)],1)],1):_vm._e(),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_ingredient')) ||
          _vm.user.permissions.includes('get_recipie') ||
          _vm.user.permissions.includes('get_menu')
        )?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-call-split")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Resturant")))])],1)]},proxy:true}],null,false,100861030)},[(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_ingredient')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/ingredient"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("IngredientsMenu")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_recipie')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/recipie"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Recipies")))])],1)],1):_vm._e(),(
            _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_menu')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/menu"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("CusineMenu")))])],1)],1):_vm._e()],1):_vm._e(),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_subscribe')) ||
          _vm.user.permissions.includes('cancel_subscribe')
        )?_c('v-list-group',{staticClass:"noBackground",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-up-bold-box-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Sales")))])],1)]},proxy:true}],null,false,3167544403)},[(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_subscribe')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id="}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Subscriptions")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_subscribe')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/subscribe?type=active&customer_name=&customer_mobile=&city_id=&branch_id="}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("ActiveSubscriptions")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_subscribe')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/subscribe?type=stop&customer_name=&customer_mobile=&city_id=&branch_id="}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("StopSubscriptions")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('get_subscribe')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/subscribe?type=expired&customer_name=&customer_mobile=&city_id=&branch_id="}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("ExpiredSubscriptions")))])],1)],1):_vm._e(),(
            _vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('cancel_subscribe')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/subscribe?type=removed&customer_name=&customer_mobile=&city_id=&branch_id="}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("RemovedSubscriptions")))])],1)],1):_vm._e()],1):_vm._e(),(

         _vm.user.permissions &&
          _vm.user.permissions.includes('get_admin') ||
          _vm.user.permissions.includes('get_permission') ||
          _vm.user.permissions.includes('get_activity')
        )?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("WorkGroup")))])],1)]},proxy:true}],null,false,129504022)},[(
             _vm.user &&
          _vm.user.permissions &&
          _vm.user.permissions.includes('get_activity')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/customers"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Customers")))])],1)],1):_vm._e(),(
             _vm.user &&
          _vm.user.permissions &&
          _vm.user.permissions.includes('get_activity')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/drivers"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Drivers")))])],1)],1):_vm._e(),(
          _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_admin')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/user"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("Users")))])],1)],1):_vm._e(),(
          _vm.user && _vm.user.permissions && _vm.user.permissions.includes('get_permission')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/user_permission"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("UsersPermissions")))])],1)],1):_vm._e(),(
             _vm.user &&
          _vm.user.permissions &&
          _vm.user.permissions.includes('get_activity')
          )?_c('v-list-item',{attrs:{"link":"","to":"/admin/activities"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-menu"},[_vm._v(_vm._s(_vm.$t("WorkGroupActivities")))])],1)],1):_vm._e()],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","to":"/admin/areasCustomers"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dialpad")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("AreasCustomers")))])],1),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('export_kitchen_today')) ||
          _vm.user.permissions.includes('export_quantities_today') ||
          _vm.user.permissions.includes('export_subscription_data') ||
          _vm.user.permissions.includes('export_renwal_data') ||
          _vm.user.permissions.includes('export_quantities_today')
        )?_c('v-list-item',{attrs:{"link":"","to":"/admin/reportPage"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dialpad")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Reports")))])],1):_vm._e(),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('verified_data'))
        )?_c('v-list-item',{attrs:{"link":"","to":"/admin/approveReports"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dialpad")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("ApproveReports")))])],1):_vm._e(),(
          (_vm.user &&
            _vm.user.permissions &&
            _vm.user.permissions.includes('import_customer')) ||
          _vm.user.permissions.includes('import_subscribe')
        )?_c('v-list-item',{attrs:{"link":"","to":"/admin/importSubscribes"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dialpad")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("ImportSubscribes")))])],1):_vm._e()],1)],1),_c('v-app-bar',{staticClass:"bg-secondary",style:(_vm.lang == 'ar' ? 'direction:rtl;' : 'direction:ltr;'),attrs:{"app":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('div',[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('div',{staticClass:"d-flex align-center",class:_vm.lang == 'ar' ? 'float-left ml-3' : 'float-right mr-3'},[_c('v-menu',{attrs:{"top":"","left":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.lang == 'en')?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"40","height":"40","src":"/assets/ar.png","alt":""},on:{"click":function($event){return _vm.changeLang('ar')}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"40","height":"40","src":"/assets/en.png","alt":""},on:{"click":function($event){return _vm.changeLang('en')}}}),_c('p',_vm._g(_vm._b({staticClass:"mx-3 mt-4 clr-primary"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user.admin.name)+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"small":"","color":"danger","text":""},on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t("Logout"))+" "),_c('v-icon',{staticClass:"my-0 mr-7",attrs:{"dense":""}},[_vm._v("mdi-logout")])],1)],1)],1)],1)],1)],1)])],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"app":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }